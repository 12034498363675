import { render, staticRenderFns } from "./Sao-Bonifacio.vue?vue&type=template&id=ad68d938&scoped=true&"
import script from "./Sao-Bonifacio.vue?vue&type=script&lang=js&"
export * from "./Sao-Bonifacio.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ad68d938",
  null
  
)

export default component.exports